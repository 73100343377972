// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// 年数（コピーライト）
$(function(){
  var year = new Date().getFullYear();
  $("#year").html(year);
});

// ヘッダーメニュー
$(function() {
  var state = false;
  var pos;

  $(".header-menu-btn").on("click", function() {
    if (state == false) {
      pos = $(window).scrollTop();
      $('body').addClass('is-fixed').css({'top': -pos});
      state = true;
    } else {
      $('body').removeClass('is-fixed').css({'top': 0});
      window.scrollTo(0, pos);
      state = false;
    }
  
    $(this).toggleClass('active');
    $('.header-nav, .header-layer').toggleClass('active');
  });
  
  // 領域外をクリック時
  $('.header-layer').on("click", function() {
    $('.header-menu-btn').removeClass('active');
    $('.header-nav, .header-layer').removeClass('active');
    $('body').removeClass('is-fixed').css({'top': 0});
    window.scrollTo(0, pos);
    state = false;
  });
});

// slick
$(function () {
  $("#kvSlider")
    .on("init", function () {
      $('#kvSlider .slick-slide[data-slick-index="0"]').addClass("add-animation");
    })
    .slick({
      autoplay: true,
      fade: true,
      speed: 2000,
      arrows: false,
      autoplaySpeed: 4000,
      pauseOnFocus: false,
      pauseOnHover: false, 
    })
    .on({
      beforeChange: function (event, slick, currentSlide, nextSlide) {
        $(".slick-slide", this).eq(nextSlide).addClass("add-animation");
        $(".slick-slide", this).eq(currentSlide).addClass("remove-animation");
      },
      afterChange: function () {
        $(".remove-animation", this).removeClass(
          "remove-animation add-animation"
        );
      },
    });
});
